<template>
  <b-modal
    @show="initGroups"
    id="share"
    body-class="p-1"
    size="md"
    v-model="show"
    title="Select Group Access"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >
    <div class="">
      <b-card class="text-center">
        <b-row class="align-items-baseline pb-3">
          <b-col cols="3">
            <span>Groups:</span>
          </b-col>
          <b-col cols="9">
            <div
              class="d-flex justify-content-start pt-3"
              v-if="groups !== null"
            >
              <multiselect
                v-model="selectedGroups"
                :options="groups"
                :multiple="true"
                :close-on-select="false"
                label="name"
                track-by="id"
                :showLabels="false"
                placeholder
                class="simple-select "
                :searchable="false"
              >
                <template slot="selection" slot-scope="{ values }"
                  >{{ values.length }}
                  {{ values.length | pluralize('group') }}
                  selected</template
                >
                <template slot="option" slot-scope="group">
                  <div>
                    <input
                      type="checkbox"
                      :checked="isGroupSelected(group.option)"
                      class="mr-1"
                    />
                    {{ group.option.name }}
                  </div>
                </template>
              </multiselect>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>

    <template #modal-footer>
      <b-button size variant="secondary" @click="closeModal">Close</b-button>

      <b-button size variant="primary" @click="submit" class="ml-3"
        >Submit</b-button
      >
    </template>
  </b-modal>
</template>
<script>
// components
import Multiselect from 'vue-multiselect'
export default {
  name: 'ShareInstitution',
  components: {
    Multiselect
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    institution: {
      required: true,
      type: Object,
      default() {
        return {}
      }
    },
    groups: {
      required: true,
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return { selectedGroups: [] }
  },
  computed: {},
  methods: {
    initGroups() {
      if (this.institution.group_ids != null)
        this.selectedGroups = this.groups.filter(g =>
          this.institution.group_ids.includes(g.id)
        )
      else this.selectedGroups = []
    },
    isGroupSelected(option) {
      return this.selectedGroups.map(ag => ag.name).includes(option.name)
    },
    closeModal: function() {
      this.institution = {}
      this.show = false
      this.$emit('modalClosed')
    },
    submit: function() {
      //Api call to update grps
      this.$http.post(
        '/api/groups/add_groups_to_institution',
        {
          institution: this.institution.id,
          groups: this.selectedGroups.map(g => g.id)
        },
        { handleErrors: true }
      )
      this.$emit(
        'groupsUpdated',
        this.selectedGroups.map(g => g.id)
      )
      this.institution = {}
      this.show = false
    }
  }
}
</script>

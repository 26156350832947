<template>
  <div class="modal-content">
    <b-list-group class="no-border" :class="{ loading: this.loading }">
      <div class="flex-row pt-1">
        <div class=" m-auto">
          <b-row class="justify-content-center list-group-container pl-4 pr-4">
            <b-col md="12" class="no-padding">
              <div class="row">
                <div class="col-sm-6 pr-0">
                  <b-form-group>
                    <label for="Search" class="mb-1">
                      <b>Search All Institutions</b>
                    </label>
                    <b-form-input
                      type="text"
                      ref="searchInstitution"
                      class="form-control rounded"
                      placeholder="FI Name, Federal Reserve ID, Regulatory ID or ABA"
                      v-model="searchTextField"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-3">
                  <b-form-group>
                    <label for="City" class="mb-1">
                      <b>City</b>
                    </label>
                    <b-form-input
                      type="text"
                      id="city"
                      class="form-control rounded"
                      placeholder="Enter City (optional)"
                      v-model="searchCityField"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm-3 pl-0">
                  <b-form-group>
                    <label for="State" class="mb-1">
                      <b>State</b>
                    </label>
                    <b-form-select
                      v-model="searchState"
                      @input="searchInstitutions"
                    >
                      <template slot="first">
                        <option :value="null">State (optional)</option>
                      </template>
                      <option
                        :value="state.code"
                        v-for="(state, index) in states"
                        :key="index"
                        >{{ state.name }}</option
                      >
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="border-top">
                <v-wait for="fetchingFIs">
                  <template slot="waiting">
                    <content-placeholders :rounded="true">
                      <content-placeholders-text
                        :lines="7"
                      ></content-placeholders-text>
                    </content-placeholders>
                  </template>
                  <div
                    class="filter-result"
                    v-on:scroll.passive="fetchMoreData($event)"
                  >
                    <b-card class="text-center">
                      <b-row>
                        <b-col md="12" class="pl-0">
                          <div>
                            <b-card class="text-left">
                              <b-list-group
                                v-if="emptyInstitutions"
                                class="text-center"
                              >
                                <b-list-group-item>
                                  <i>No Institutions found ...</i>
                                </b-list-group-item>
                              </b-list-group>
                              <b-list-group v-else>
                                <div>
                                  <b-list-group-item
                                    class="p4 pl-0 "
                                    :disabled="true"
                                    v-if="isPurchasedInstitutions"
                                  >
                                    Recently Viewed Reports
                                  </b-list-group-item>
                                </div>
                                <div
                                  v-for="institution in institutions"
                                  :key="institution.id"
                                >
                                  <b-list-group-item
                                    @click="setSelectedInstitution(institution)"
                                    class="p4 pl-0 "
                                    :class="{
                                      selected:
                                        institution.id ===
                                        selectedInstitution.id
                                    }"
                                    :disabled="loading"
                                    :ref="'inst_' + institution.id"
                                  >
                                    <span :title="institution.alternate_names"
                                      >{{ institution.full_name }}&#44;
                                      {{ institution.city }}&#44;
                                      {{ institution.state }}
                                      {{ '[' + institution.id + ']' }}</span
                                    >
                                    <span
                                      class="manage--query-group"
                                      v-if="
                                        isPurchasedInstitution(institution.id)
                                      "
                                    >
                                      <button
                                        type="submit"
                                        class=" btn btn-primary btn-sm mx-1"
                                        @click.stop="share(institution)"
                                      >
                                        Share
                                      </button>
                                    </span></b-list-group-item
                                  >
                                </div>
                              </b-list-group>
                            </b-card>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                </v-wait>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-list-group>
    <ShareInstitution
      :show="showModal"
      :institution="selectedInstitution"
      :groups="groups"
      @groupsUpdated="updatePurchasedInstitutions"
      @modalClosed="updateShowModal"
    >
    </ShareInstitution>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapState, mapGetters, mapMutations } from 'vuex'
import ShareInstitution from './ShareInstitution'

export default {
  name: 'EnterpriseUserSelectInstitution',
  components: {
    ShareInstitution
  },
  props: {
    purchasedInstitutions: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    states: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    reportType: {
      required: true,
      type: String,
      default: 'advisor'
    },
    currentSelectedInstitution: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      stage: 'summary',
      searchText: '',
      searchTextField: '',
      searchCity: null,
      searchCityField: null,
      searchState: null,
      isPurchasedInstitutions: true,
      remainingFreeReports: 0,
      selectedInstitution: {},
      institutions: [],
      loading: false,
      fetchingData: false,
      offset: 0,
      purchasedInstOffset: 0,
      showModal: false,
      groups: [],
      currentlyAddedInstitutionIds: []
    }
  },
  mounted() {
    this.institutions = this.purchasedInstitutions
    this.isPurchasedInstitutions = true
    this.selectedInstitution = this.currentSelectedInstitution
  },
  computed: {
    ...mapGetters('Authentication', ['currentUserEmail']),
    ...mapGetters('Ability', ['isProfilePartiallyEnabled']),
    ...mapState('Authentication', {
      mockUser: state => state.mockUser
    }),
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    isPartnerAdminMock() {
      return this.partnerSite && this.mockUser
    },
    emptyInstitutions() {
      return this.institutions.length === 0
    },

    institutionIds: function() {
      return this.institutions.map(i => i.id)
    },

    purchasedInstitutionIds: function() {
      return this.purchasedInstitutions.map(si => si.id)
    }
  },
  methods: {
    ...mapMutations(['setStoredInstitutions', 'setRedirectUrl']),
    ...mapMutations('Profile', ['setInstitution', 'setReportId']),
    ...mapMutations('Advisor', { setInstitutionForAdvisor: 'setInstitution' }),
    ...mapMutations({ setInstitutionForReporter: 'setInstitution' }),
    ...mapMutations('CustomPeerGroup', ['resetCustomPeerGroupDetails']),

    isPurchasedInstitution(id) {
      return this.purchasedInstitutionIds.includes(id)
    },
    share(institution) {
      this.selectedInstitution = institution
      if (!this.groups.length) {
        this.fetchGroups()
      } else {
        this.showModal = true
      }
    },
    fetchGroups() {
      this.$http
        .get('/api/groups', {
          handleErrors: true
        })
        .then(res => {
          this.groups = res.data.groups || []
          this.showModal = true
        })
    },
    searchInstitutions: function() {
      this.showModal = false
      this.selectedInstitution = {}
      this.resetInstitution()
      if (!this.searchText && !this.searchCity && !this.searchState) {
        this.institutions = this.purchasedInstitutions
        this.isPurchasedInstitutions = true
        return
      } else {
        this.isPurchasedInstitutions = false
        this.$wait.start('fetchingFIs')
        this.offset = 0
        return this.$http
          .get('/api/users/new_order', {
            params: {
              search_text: this.searchText,
              city: this.searchCity,
              state: this.searchState
            },
            handleErrors: true
          })
          .then(res => {
            this.institutions = res.data.financial_institutions
            if (this.reportType === 'profile') {
              this.removeBHCsIfPartialAccess()
            }
          })
          .then(() => {
            this.loading = false
            this.$wait.end('fetchingFIs')
          })
      }
    },
    removeBHCsIfPartialAccess() {
      if (this.isProfilePartiallyEnabled) {
        this.institutions = this.institutions.filter(institution => {
          return institution.fi_type !== 'bhc'
        })
      }
    },
    fetchMoreData(event) {
      //if (!this.isPurchasedInstitutions) {
      let el = event.target
      if (Math.round(el.scrollTop) >= el.scrollHeight - el.offsetHeight) {
        this.isPurchasedInstitutions
          ? this.fetchAdditionaPurchasedInstitutions()
          : this.fetchAdditionalInstitutions()
      }
      //}
    },
    fetchAdditionaPurchasedInstitutions() {
      this.showModal = false
      if (this.purchasedInstOffset < this.institutions.length) {
        this.loading = true
        this.purchasedInstOffset = this.institutions.length
        this.$http
          .get('/api/advisors', {
            params: {
              offset: this.purchasedInstOffset
            },
            handleErrors: true
          })
          .then(res => {
            this.institutions.push(...res.data.banks)
            if (this.reportType === 'profile') {
              this.removeBHCsIfPartialAccess()
            }
          })
          .then(() => {
            this.loading = false
          })
      }
    },
    fetchAdditionalInstitutions() {
      this.showModal = false
      this.isPurchasedInstitutions = false

      if (this.offset < this.institutions.length) {
        this.loading = true
        this.offset = this.institutions.length
        this.$http
          .get('/api/users/new_order', {
            params: {
              search_text: this.searchText,
              city: this.searchCity,
              state: this.searchState,
              offset: this.offset
            },
            handleErrors: true
          })
          .then(res => {
            this.institutions.push(...res.data.financial_institutions)
            if (this.reportType === 'profile') {
              this.removeBHCsIfPartialAccess()
            }
          })
          .then(() => {
            this.loading = false
          })
      }
    },

    search: _.debounce(function() {
      this.searchInstitutions()
    }, 500),

    setSelectedInstitution: function(institution) {
      this.selectedInstitution = institution
      if (!this.purchasedInstitutionIds.includes(institution.id)) {
        //check if an add call is currently in progress for this id
        if (!this.currentlyAddedInstitutionIds.includes(institution.id)) {
          //update institution as purchased in db..if not already purchased
          this.currentlyAddedInstitutionIds.push(institution.id)
          this.$http
            .post(
              '/api/users/add_institutions',
              { institutions: [institution.id] },
              { handleErrors: true }
            )
            .then(
              this.addInstitutionToPurchasedList(institution),
              this.set(institution),
              this.currentlyAddedInstitutionIds.splice(
                this.currentlyAddedInstitutionIds.indexOf(institution.id),
                1
              )
            )
        } //Nothing to do if already in the add list
      } else {
        this.set(institution)
      }
    },
    addInstitutionToPurchasedList(institution) {
      this.purchasedInstitutions.unshift(institution)
    },
    set(institution) {
      //this.searchText = institution.name
      if (this.reportType === 'advisor') {
        this.setInstitutionForAdvisor({
          id: institution.id,
          name: institution.name,
          fi_type: institution.fi_type,
          state: institution.state,
          city: institution.city
        })
        this.resetCustomPeerGroupDetails()
      } else if (this.reportType === 'reporter') {
        this.setInstitutionForReporter({
          id: institution.id,
          name: institution.name,
          fi_type: institution.fi_type
        })
        this.resetCustomPeerGroupDetails()
      } else {
        this.setInstitution({
          id: institution.id,
          name: institution.name,
          fiType: institution.fi_type
        })
      }
      this.setReportId(null)
    },
    resetInstitution() {
      if (this.reportType === 'advisor') {
        this.setInstitutionForAdvisor({})
        //this.resetCustomPeerGroupDetails()
      } else if (this.reportType === 'reporter') {
        this.setInstitutionForReporter({})
        //this.resetCustomPeerGroupDetails()
      } else {
        this.setInstitution({})
      }
    },
    updatePurchasedInstitutions(groupIds) {
      this.showModal = false
      this.selectedInstitution.group_ids = groupIds
      let fi = this.purchasedInstitutions.find(
        fi => fi.id === this.selectedInstitution.id
      )
      fi.group_ids = groupIds
    },
    updateShowModal() {
      this.showModal = false
    }
  },
  watch: {
    searchTextField(newVal) {
      if (newVal !== this.searchText) {
        this.searchText = newVal
        this.search()
      }
    },
    searchCityField(newVal) {
      if (newVal !== this.searchCity) {
        this.searchCity = newVal
        this.search()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.filter-result,
.search-bar {
  font-size: 14px;
}
.list-group-container {
  padding-top: 1em;
  .no-padding {
    padding: 0;
  }
}
.filter-result {
  min-height: 10rem;
  height: 20rem;
  overflow-y: scroll;
  .card {
    .card-body {
      padding: 0.5rem;
      overflow-x: hidden;
      .list-group-item {
        padding: 0.25em 1em;
        border: none;
        cursor: pointer;
        &.selected {
          background-color: #f0f3f5;
        }
        &:hover {
          background-color: #f0f3f5;
        }
      }
      .list-group-item.disabled {
        cursor: not-allowed;
      }
    }
  }
}
.card-sub-header {
  margin-top: 1em;
  font-weight: bold;
}

.filter-result {
  .card {
    margin-bottom: 0;
  }
}

.list-group-item {
  &.no-border {
    border: none;
  }
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}
.manage--query-group {
  display: none;

  .btn {
    border-radius: 0.25rem;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 4rem;
  }
}

.filter-result {
  .list-group-item {
    &:hover {
      .manage--query-group {
        display: inline;
      }
    }
  }
}
</style>
